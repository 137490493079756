import zxcvbn from 'zxcvbn'

export default {
    methods: {
        async validatePassword(value) {
            const result = await zxcvbn(value)
            const passwordTestResult = {}
            passwordTestResult.message = result.feedback.warning
            passwordTestResult.strength = result.score * 25
            passwordTestResult.field = result.password
            switch (result.score) {
            case 1:
                passwordTestResult.color = 'red darken-2'
                break;
            case 2:
                passwordTestResult.color = 'orange darken-2'
                break;
            case 3:
                passwordTestResult.color = 'green darken-2'
                break;
            case 4:
                passwordTestResult.color = 'green darken-2'
                break;
            default:
                break;
            }
            passwordTestResult.value = !(result.score < 3)
            return passwordTestResult
        }
    }
}
